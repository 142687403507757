<template>
  <div class="py-6">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card pb-4">
            <div class="card-header border-bottom-0">
              <form class="d-flex align-items-center">
                <input type="search" v-model="filter.name" class="form-control me-4" placeholder="Họ tên">
                <input type="search" v-model="filter.email"  class="form-control me-4" placeholder="Email">
                <input type="search" v-model="filter.phone"  class="form-control me-4" placeholder="SĐT">
                <select class="form-control me-4" v-model="filter.role">
                  <option value="">-- Quyền hạn --</option>
                  <option value="user">User thường</option>
                  <option value="admin">Admin</option>
                  <option value="reviewer_admin">Điều phối chấm bài</option>
                  <option value="reviewer">Người chấm bài</option>
                </select>
                <select class="form-control" v-model="filter.status">
                  <option value="">-- Trạng thái --</option>
                  <option value=1>Đang hoạt động</option>
                  <option value=0>Tạm khóa</option>
                </select>
              </form>
            </div>
            <table class="table mb-4 text-nowrap list">
              <thead class="table-light">
              <tr>
                <th scope="col">Họ tên</th>
                <th scope="col">Email</th>
                <th scope="col">SĐT</th>
                <th scope="col">Quyền</th>
                <th scope="col">Trạng thái</th>
                <th scope="col"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(user, index) in users.data" :key="'user' + index">
                <td class="align-middle">
                  <div class="d-lg-flex align-items-center">
                    <div class="thumbnail">
                      <router-link :to="{name: 'AdminEditUser', params: {uid: user.uid}}">
                        <img :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'" alt="" class="avatar">
                      </router-link>
                    </div>
                    <div class="ms-lg-3 mt-2 mt-lg-0">
                      <h4 class="mb-1 text-primary-hover">
                        <router-link :to="{name: 'AdminEditUser', params: {uid: user.uid}}" class="text-inherit">
                          {{ user.name }}
                        </router-link>
                      </h4>
                    </div>
                  </div>
                </td>
                <td class="align-middle">{{ user.email }}</td>
                <td class="align-middle">{{ user.phone }}</td>
                <td class="align-middle">
                  <span class="badge rounded-pill bg-secondary" v-if="user.role === 'user'">User thường</span>
                  <span class="badge rounded-pill bg-danger" v-if="user.role === 'admin'">Admin</span>
                  <span class="badge rounded-pill bg-info" v-if="user.role === 'reviewer'">Người chấm bài</span>
                </td>
                <td class="align-middle">
                  <span class="badge rounded-pill bg-success" v-if="user.status === 1">Đang hoạt động</span>
                  <span class="badge rounded-pill bg-danger" v-if="user.status === 0">Tạm ngưng</span>
                </td>
                <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="userDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <router-link class="dropdown-item" :to="{name: 'AdminUserDetail', params: {uid: user.uid}}">
                                  <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                </router-link>
                                 <router-link class="dropdown-item" :to="{name: 'AdminEditUser', params: {uid: user.uid}}">
                                  <i class="fe fe-edit-2 dropdown-item-icon"></i> Sửa
                                </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentUser(user)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeUserModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                              </span>
                            </span>
                </td>
              </tr>
              </tbody>
            </table>
            <v-pagination
                v-if="users.paginatorInfo.lastPage > 1"
                v-model="users.paginatorInfo.currentPage"
                :pages="users.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadUsers"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeUserModal" tabindex="-1" role="dialog" aria-labelledby="removeUserModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="removeUserModalLabel">
            Xóa người dùng
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn xóa <span class="fw-bold"> {{ currentUser.name }}</span>?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" data-bs-dismiss="modal" type="Button" v-on:click="deleteUser">
            Xóa người dùng
          </button>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" title="Cập nhật thông tin user"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {getWhereCondition, openModal} from "@/core/services/utils.service";
import Modal from "@/components/Modal";

export default {
  name: "UserIndex",
  components: {
    VPagination,
    Modal
  },
  data() {
    return {
      users: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      currentUser: {name: ""},
      filter: {
        name: "",
        email: "",
        phone: "",
        status: "",
        role: ""
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
    }
  },
  methods: {
    loadUsers() {
      let filters = {};

      for (let key in this.filter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.filter.hasOwnProperty(key)) {
          if (key === "name" || key === "email" || key === "phone") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "LIKE",
                value: "%" + this.filter[key] + "%"
              }
            }
          } else if (key === "status" || key === "role") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "EQ",
                value: this.filter[key]
              }
            }
          }
        }
      }

      let where = getWhereCondition(filters);

      let query = `query {
        users(first: 5, page: ${this.users.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + where + `) {
          data {
            id
            uid
            name
            phone
            email
            status
            role
            created_at
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.users) {
              this.users = data.data.users;
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    setCurrentUser(user) {
      this.currentUser = user;
    },
    deleteUser() {
      this.messageModalTitle = "Xóa người dùng";

      let query = `mutation($id: ID!) {
        deleteUser(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentUser.id})
          .then(({data}) => {
            if (data.data.deleteUser) {
              this.messageModalMessage = "Xóa người dùng thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.loadUsers();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    }
  },
  mounted() {
    this.loadUsers();
  },
  watch: {
    filter: {
      handler() {
        this.users.paginatorInfo.currentPage = 1;
        this.loadUsers();
      },
      deep: true
    }
  },
}
</script>
